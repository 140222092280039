<p class="tw-text-center" bitTypography="body1">
  {{ "newDeviceVerificationNoticeContentPage2" | i18n }}
</p>

<a
  href="#"
  bitButton
  (click)="navigateToTwoStepLogin($event)"
  buttonType="primary"
  class="tw-w-full tw-mt-4"
  data-testid="two-factor"
>
  {{ "turnOnTwoStepLogin" | i18n }}
  <i
    class="bwi bwi-external-link bwi-lg bwi-fw"
    aria-hidden="true"
    [ngClass]="{ 'md:tw-hidden': !isDesktop }"
  >
  </i>
</a>
<a
  href="#"
  bitButton
  (click)="navigateToChangeAcctEmail($event)"
  buttonType="secondary"
  class="tw-w-full tw-mt-4"
  data-testid="change-email"
>
  {{ "changeAcctEmail" | i18n }}
  <i
    class="bwi bwi-external-link bwi-lg bwi-fw"
    aria-hidden="true"
    [ngClass]="{ 'md:tw-hidden': !isDesktop }"
  ></i>
</a>

<div class="tw-flex tw-justify-center tw-mt-6" *ngIf="!permanentFlagEnabled">
  <a bitLink linkType="primary" (click)="remindMeLaterSelect()" data-testid="remind-me-later">
    {{ "remindMeLater" | i18n }}
  </a>
</div>
