export enum NotificationType {
  SyncCipherUpdate = 0,
  SyncCipherCreate = 1,
  SyncLoginDelete = 2,
  SyncFolderDelete = 3,
  SyncCiphers = 4,

  SyncVault = 5,
  SyncOrgKeys = 6,
  SyncFolderCreate = 7,
  SyncFolderUpdate = 8,
  SyncCipherDelete = 9,
  SyncSettings = 10,

  LogOut = 11,

  SyncSendCreate = 12,
  SyncSendUpdate = 13,
  SyncSendDelete = 14,

  AuthRequest = 15,
  AuthRequestResponse = 16,

  SyncOrganizations = 17,
  SyncOrganizationStatusChanged = 18,
}
